.about-page {
    background-image: url("../assets/images/background-and-cast.png");
}

.about-page .app-title {
    width: 80%;
    margin-top: 260px;
}

.about-page .subtitle{
    color: #FFFFFF;
    max-width: 80%;
    font-size: 1.7rem;
    text-shadow: 2px 2px 6px rgba(0, 0, 0, 1);
}

.about-page .page-container .premiere-block {
    padding: 20px 0;
    border-top: 1px solid #4E4E4E;;
    border-bottom: 1px solid #4E4E4E;;
    width: 90%;
    margin-bottom: 10px;
}

.about-page .page-container .premiere-line-1 {
    margin: 0 0 10px 0;
}

.about-page .page-container .premiere-line-2 {
    font-weight: 700;
    margin: 0;
    color: #A51B17;
    font-size: 1.5rem;
}
