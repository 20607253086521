.login-page {
    justify-content: flex-start;
    background-image: url("../assets/images/map.png");
    background-position: center center;
    background-size: cover;
    width: 100%;
    height: 100Vh;
}

.login-page .viasat-logo {
    height: 10vh;
    margin: 60px 0 20px 0;
}

.login-page p {
    width: 75%;
    text-shadow: 2px 2px 6px rgba(0, 0, 0, 1);
    color: #FFFFFF;
    margin-bottom: 20px;
}

.login-input {
    text-align: center;
    border: none;
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 10px;
    font-weight: 700;
}
