.question-page {
    justify-content: center;
    background-position: center center;
    background-size: cover;
    width: 100%;
    min-height: 100%;
}

.question-page .question-container {
    flex: 1;
    width: 100%;
    height: inherit;
}

.question-page .question-container.answered {
    background: rgba(0, 0, 0, 0.75);
}

.question-page .question {
    margin-top: 7rem;
    padding: 1.8rem;
    background: #FFFFFF;
    border-radius: 16px;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.6);
    font-size: 1rem;
}

.question-page .answer-result {
    color: #FFFFFF;
}

.question-page .answer-result img {
    margin-right: 12px;
    width: 46px;
    height: 46px;
}

