@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700&display=swap');

body {
  margin: 0 auto;
  font-family: 'Montserrat', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
}

@media only screen
and (max-device-width: 560px)
and (orientation: portrait) {
    html {
        font-size: 75%;
    }
}

@media only screen
and (max-device-width: 667px)
and (orientation: landscape) {
    html {
        font-size: 75%;
    }
}

@media only screen
and (max-device-height: 700px)
and (orientation: landscape) {
    html {
        font-size: 75%;
    }
}

@media only screen
and (max-device-height: 640px)
and (orientation: portrait) {
    html {
        font-size: 75%;
    }
}

