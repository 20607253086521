.answer-button{
    background-color: #FFF;
    color: #A41A16;
    padding: 0.9rem 1.8rem;
    font-weight: 700;
    min-width: 240px;
    border: none;
    border-radius: 30px;
    margin-bottom: 10px;
}

.answer-button:hover{
    background-color: #A41A16;
    color: #FFF;
}

.answer-button.correct {
    color: #FFFFFF;
    background: #58BE3F;
}

.answer-button.wrong {
    color: #FFFFFF;
    background: #A51B17 ;
}
