:root {
    --swiper-pagination-bottom: 20px
}

.App {
    position: relative;
    text-align: center;
    height: 100vh;
}

@media (orientation: landscape) {
    .App  {
        width: 100%;
        max-width: 720px;
    }
}

@media screen and (orientation: landscape) and ( max-height: 640px ) {
    .App  {
        width: 100%;
    }
}

@media (orientation: portrait) {
    .App {
        width: 100%;
    }
}

.content {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    height: inherit;
}

.navbar {
    position: sticky;
    bottom: 0;
    width: inherit;
    z-index: 3;
    box-shadow: 0 -2px 6px rgba(0, 0, 0, 0.6);
    border-bottom: solid 1px #EBEBEB;
    background: #FFFFFF;
    padding-bottom: 0;
}

.bottom-tab-nav {
    border-top: solid 1px #EBEBEB;
}

.bottom-nav-link .row>* {
    padding-left: 0;
    padding-right: 0;
}

.nav-link, .bottom-nav-link {
    color: #808080;
    font-size: 1.2rem;
}

.bottom-nav-link svg{
    margin-bottom: 10px;
}

.bottom-nav-link:hover{
    color: #A41A16;
}

.bottom-nav-link.active{
    border-bottom: #A41A16 solid 10px;
}

.bottom-nav-link.active .bottom-tab-label{
    color: #303030;
}

.bottom-nav-link:hover svg{
    color: #A41A16;
}

.bottom-nav-link.active svg{
    color: #A41A16;
}

.bottom-tab-label {
    font-weight: 700;
    font-size: 0.9rem;
}


.swiper {
    width: 100%;
    height: 100vh;
}

.swiper-slide {
    text-align: center;
    font-size: 1.2rem;
    background: #A51B17;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;

    background: linear-gradient(#A51B17 0%, #261301 100%);

    overflow: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
}


.swiper-slide::-webkit-scrollbar {
    display: none;
}

.my-swiper .swiper-pagination-bullet {
    background: rgba(255, 255, 255, 0.5);
}

.my-swiper .swiper-pagination-bullet-active {
    background: rgba(255, 255, 255, 1);
}

.slide-content {
    color: white;
    background-image: url("assets/images/map.png");
    background-position: center center;
    background-size: cover;
    border-radius: 16px;
    background-repeat: no-repeat;
    margin: 50px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.7);
    display: flex;
    flex-direction: column;
    align-items: center;

    max-height: 800px;
}

.slide-content .logo-glow {
    position: relative;
    top: 0;
    left: 0;
    height: 18vh;
}

.slide-content .viasat-logo {
    position: absolute;
    top: 4vh;
    left: 13vh;
    height: 10vh;
}

.slide-content .app-title {
    width: 100%;
}

.slide-content p{
    max-width: 80%;
    font-size: 1.2rem;
    text-shadow: 2px 2px 6px rgba(0, 0, 0, 1);
}

.slide-content .cast-image {
    width: 65%;
}

.inner-page {
    background-image: linear-gradient(#000000 50%, #A51B17 100%);
    background-position: top center;
    height: 100%;
    overflow: hidden;
}

.inner-page::-webkit-scrollbar {
    display: none;
}

.page-title {
    position: fixed;
    width: 100%;
    max-width: 720px;
    z-index: 2;
    background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 100%);
    height: 5rem;
    padding: 10px 50px;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.page-title h3 {
    margin: 0;
    font-size: 1.2rem;
}

.page-title .viasat-logo {
    height: 93%;
}

.page {
    background-position: top center;
    background-size: contain;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.scrollable {
    width: 100%;
    height: 100%;
    overflow: auto;

    scrollbar-width: none;
    -ms-overflow-style: none;
}

.scrollable::-webkit-scrollbar {
    display: none;
}

.page-container {
    background: rgba(255, 255, 255, 0.8);
    border-radius: 16px;
    padding: 30px 10px;
    width: 85%;
    margin-bottom: 50px;
}

.page-container svg {
    font-size: 1.7rem;
}

.page-container p {
    font-size: 1rem;
    width: 80%;
    color: black;
    text-shadow: none;
    text-align: center;
    margin: 0 0 30px 0;
}

.list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 120px 0 230px 0;
}

.error {
    background: #FFFFFF;
    border-radius: 16px;
    padding: 10px;
    color: #A41A16;
    font-size: 1rem;
    text-align: center;
}

