.rank-item {
    position: relative;
    background: #FFFFFF;
    border-radius: 10px;
    border: 4px solid rgba(255, 255, 255, 0);
    width: 90%;
    padding: 10px 10px;
    font-size: 1.2rem;
    margin-bottom: 10px;
}

.rank-item.current-user {
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.6);
}

.rank-item .user-info {
    font-weight: 700;
    margin-bottom: 10px;
}

.rank-item .user-info .rank {
    margin-right: 14px;
}

.rank-item .score img {
    margin-right: 10px;
    width: 20px;
    height: 20px;
}

.rank-item .score .score-value, .rank-item .score .time-value {
    margin-right: 10px;
    font-weight: 700;
}

.rank-item .score .score-label, .rank-item .score .time-label {
    font-size: 0.9rem;
    color: #7F7F7F;
}

.rank-item .score span {
    color: #7F7F7F;
    margin: 0 10px;
}

.rank-item .badge {
    position: absolute;
    top: -10px;
    right: 16px;
}

.rank-item .badge img {
    width: 36px;
    height: 36px;
}

